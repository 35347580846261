import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Tiktok,
} from 'views/common/components/UI/SocialMediaIcons';

const PREFIX = 'FollowUs';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listIcon: `${PREFIX}-listIcon`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },

  [`& .${classes.title}`]: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
  },

  [`& .${classes.list}`]: {
    marginTop: theme.typography.pxToRem(7),
    marginBottom: theme.typography.pxToRem(25),
  },

  [`& .${classes.listItem}`]: {
    width: 'auto',
    display: 'inline-flex',
    marginRight: theme.typography.pxToRem(14),
  },

  [`& .${classes.listIcon}`]: {
    minWidth: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(40),
    height: theme.typography.pxToRem(40),
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #797979',
    borderRadius: '50%',
    "& [class*='MuiSvgIcon-root']": {
      color: theme.palette.grey['50'],
    },
  },
}));

const socialLinks = [
  {
    href: 'https://www.facebook.com/EagleRider/',
    icon: <Facebook />,
  },
  {
    href: 'https://twitter.com/eaglerider',
    icon: <Twitter viewBox="0 0 30 30" />,
  },
  {
    href: 'https://www.instagram.com/eaglerider/',
    icon: <Instagram />,
  },
  {
    href: 'https://www.youtube.com/user/EagleRiderCorporate',
    icon: <Youtube />,
  },
  {
    href: 'https://www.tiktok.com/@clubeaglerider',
    icon: <Tiktok />,
  },
];

const FollowUs = ({ title }) => (
  <Root className={classes.root}>
    <Typography variant="h6" className={classes.title}>
      {title}
    </Typography>
    <List component="div" className={classes.list}>
      {socialLinks.map((link) => (
        <ListItem
          key={link.href}
          dense
          className={classes.listItem}
          href={link.href}
          rel="noopener"
          target="_blank"
          component="a"
          disableGutters>
          <ListItemIcon className={classes.listIcon}>{link.icon}</ListItemIcon>
        </ListItem>
      ))}
    </List>
    <Divider />
  </Root>
);

export default FollowUs;
